import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { AuthenticationCallbackRepositoryInterface } from '../../../domain/authentication/interfaces/repositories/authentication-callback.repository.interface';
import { AuthenticationCallbackInput } from '../../../domain/authentication/dtos/authentication-callback.input';
import { VerifyPasswordInput } from '../../../domain/authentication/dtos/verify-password.input';

@Injectable()
export class AuthenticationCallbackHttpRepository
  implements AuthenticationCallbackRepositoryInterface
{
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  loginExternal(code: AuthenticationCallbackInput): Promise<any> {
    const requestUrl = `${this._settings.authenticationUrl.v1}/oauth/external`;
    const requestHeaders = { excludeInterceptors: 'true' };
    return lastValueFrom(
      this._http.post(requestUrl, code, { headers: requestHeaders })
    );
  }

  verifyPassword(payload: VerifyPasswordInput): Promise<any> {
    const requestUrl = `${this._settings.authenticationUrl.v1}/password/verify`;
    const requestHeaders = { excludeInterceptors: 'true' };
    return lastValueFrom(
      this._http.post(requestUrl, payload, { headers: requestHeaders })
    );
  }
}
