import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { AuthenticationCallbackHttpRepository } from '../../../infrastructure/repositories/http/authentication-callback.repository';

export class AuthenticationCallbackRepositoryInstruction
  implements ClassProvider
{
  provide = Types.AuthenticationCallbackRepository;
  deps = [Types.Settings];
  useClass = AuthenticationCallbackHttpRepository;
}
